@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'DM Mono';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.settings-form-container label, .settings-password, .settings-password label{
  color: #fff !important;
  cursor: pointer;
}

.calendar-overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #00000080;
  z-index: 10; 
}

.calendar-overlay.active {
  display: block;
}

.non-printable {
  /* Styles non imprimables */
}

.print-wrapper {
  /* Styles pour la section d'impression */
  display: flex;
  flex-direction: column;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.fixed-loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.fixed-loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}
.button-input-container {
  display: flex;
  justify-content: end;
  align-items: flex-end;
  margin-top: 15px; 
  width: 100%; 
  margin-left: auto; 
} 

.button-input-container .print-btn {
  margin-left: 10px; /* Ajustez selon vos besoins */
}
@keyframes spin {
   0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.print-form-container {
  display: flex;
  flex-wrap: wrap;
}
.check-print-form {
  display: grid;
  grid-template-columns: auto repeat(3, 1fr) auto;
  max-width: 100%;
  margin: 0 auto;
  grid-gap: 10px;
}

.check-print-form p {
  width: 100%;
}

.check-print-form input {
  width: calc(100% - 10px); 
  margin-bottom: 10px;
}

@media screen and (max-width: 870px) {
  .check-print-form {
    display: block; 
  }

  .check-print-form p,
  .check-print-form input {
    width: 100%;
  }
}


