.imprimer-container {
  margin: 0 !important;
}

.check {
  position: relative;
  margin: 0;
  /* margin-left: -189px !important;
            margin-top: 189px;
            transform: rotate(270deg); */
}
.check_data {
  position: absolute;
  font-family: "DM Mono";
}
.montant {
  
}
.num_check{
  
  font-weight: 700;
}
.montant_ecrit_line1 {
  /* transform: translate(-50%); */
}
.montant_ecrit_line2 {
  transform: translate(-50%);
  width: 100%;
  text-align: center;
}
.montant_to {
}
.date {
  top: 219px;
  font-size: 14px;
}
.montant_a_fr {
  font-size: 13px;
}
.montant_le {
  font-size: 12px;
}
.montant_a_ar {
  font-size: 14px;
}

.check img {
  display: block;
}

@media print {
  .check {
    /* margin-left: -189px !important;
    margin-top: 189px; */
    border: none !important;
    page-break-before: always;
  }
  .__check_data {
    display: none;
  }
  .check-inputs-print {
    display: none;
  }
  .check-num-print {
    display: none;
  }
  .check-border {
    display: none;
  }
  .check img {
    display: none !important;
    /* position: fixed;
    top: 0;
    left: 0; */
  }
  .num_check, .num_check_header{
    display: none;
  }
  .non-printable {
    display: none !important;
  }
  .print{
    display: block !important;
  }
  .traite .montant_a_top{
    top: 37px !important;
  }
  .traite .montant_due_top{
    top: 57px !important;
  }
  .traite .montant_le_top{
    top: 58px !important;
  }
  .traite .segments_top{
    top: 93px !important;
  }
  .traite .segments_top.segments1{
    left: 220px !important;
  }
  .traite .segments_top.segments2{
    left: 260px !important;
  }
  .traite .segments4_top{
    left: 469px !important  ;
  }
  .traite .checkmontanttop{
    top: 93px !important;
  }
  .traite .checkmontanttop{
    top: 93px !important;
  }
  .traite .montant_a_bottom{
    left: 20px !important;
  }
  .traite .segments_bottom{
    top: 269px !important;
  }
  .traite .segments_bottom.segments11{
    left: 18px !important;
  }
  .traite .segments_bottom.segments22{
    left: 48px !important;
  }
  .traite .segments44{
    left: 260px !important;
  }
  .traite .montant_ecrit_line1 {
    /* left: 400px !important; */
    top: 180px !important;
  }
  .traite .middle_dates_div{
    left: 20px !important;
    top: -10px !important;
  }
  .traite .bottom_segments_div{
    left: 25px !important;
    top: -12px !important;
  }
  .traite .bankname{
    top: 270px !important;
  }
  .traite .top_pays{
    top: 37px !important
  }
  .traite .date_creation_top{
    top: 55px !important;
  }
  .traite .montant_to{
    top: 150px !important;
  }
  .traite .checkmontantbas{
    top: 150px !important;
  }
  .check-only .check-only-amount{
    top: 25px !important;
    /* top: 33px !important; */
    font-size: 14px !important;
  }
  .check-only .bottom_date_div{
    top: -3px !important;
  }
}
