.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.no-data-msg {
  margin-top: 20px;
  margin-bottom: 20px;
  padding-right: 20px;
  padding-left: 20px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.warning-text {
  color: orange;
  font-weight: bold;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loader {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.fixed-loader-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}

.fixed-loader {
  border: 8px solid rgba(255, 255, 255, 0.1);
  border-top: 8px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.event-popup {
  background-color: white;
  padding: 30px;
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  font-size: 16px;
  line-height: 1.6;
  text-align: center;
}

.event-popup button {
  margin-top: 10px;
  padding: 10px 20px;
  background-color: rgb(238, 132, 50);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.popup-container {
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 320px;
  max-width: 600px;
  z-index: 999999999999999999999999999999;
}

@media (min-width: 640px) {
  .popup-container {
    left: 60%;
  }
}

.event-popup-text {
  text-align: left;
}

body {
  margin: 0 !important;
}

.check {
  position: relative;
  margin: 0;
  /* margin-left: -189px !important;
  margin-top: 189px;
  transform: rotate(270deg); */
}

.check_data {
  position: absolute;
  font-family: 'DM Mono';
}

.montant {
  left: 540px;
  top: 20px;
}

.montant_ecrit_line1 {
  left: calc(50% + 30px);
  transform: translate(-50%);
  top: 65px;
}

.montant_ecrit_line2 {
  left: calc(50% + 30px);
  top: 90px;
  transform: translate(-50%);
  width: 100%;
  text-align: center;
}

.montant_to {
  left: 110px;
  top: 120px;
}

.date {
  top: 219px;
  font-size: 14px;
}

.montant_a_fr {
  left: 230px;
  font-size: 13px;
}

.montant_le {
  left: 315px;
  font-size: 12px;
}

.montant_a_ar {
  left: 420px;
  font-size: 14px;
}

@media print {
  body {
    overflow: hidden;

  }

  #check {
    page-break-before: always;
  }

  .print {
    page-break-before: always;
  }

  .__check_data {
    display: none;
  }

  img {
    display: none;
  }

  ::-webkit-scrollbar {
    display: none;
  }
}


.fixed-bottom-left {
  position: fixed;
  bottom: 20px;
  left: 20px;
  font-size: 12px;
}

@media (min-width: 768px) {
  .fixed-bottom-left {
      left: 290px;
  }
}
.logo-bottom-right {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 100px;
  height: 100px;
}

@media (min-width: 768px) {
  .logo-bottom-right {
    position: fixed;
    top: 700px;
  }
}
@media (max-width: 1078px) {
  .logo-bottom-right {
    position: fixed;
    top: 456px;
  }
}
@media (max-width: 886px) {
  .logo-bottom-right {
    position: fixed;
    top: 440px;
  }
}
@media (max-width: 500px) {
  .logo-bottom-right {
    position: fixed;
    top: 420px;
  }
}
@media (max-width: 270px) {
  .logo-bottom-right {
    position: fixed;
    top: 400px;
  }
}
@media (max-width: 192px) {
  .logo-bottom-right {
    position: fixed;
    top: 380px;
  }
}
@media (max-width: 144px) {
  .logo-bottom-right {
    position: fixed;
    top: 360px;
  }
}
/* @media (min-width: 1066px) {
  .logo-bottom-right {
    position: fixed;
    top: 454px;
  }
} */
/* @media (min-width: 1066px) {
  .logo-bottom-right {
    position: fixed;
    top: 454px;
  }
} */
.custom-button {
  padding: 15px 40px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  width: auto;
  margin-left: auto;
  margin-right: 0;
}

.custom-button.primary {
  background-color: #ee8432;
  color: white;
  transition: background-color 0.3s ease;
}

.custom-button.primary:hover {
  background-color: #5795be;
}

/* Modal Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

/* Modal Content */
.modal-content {
  position: relative;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 800px;
  height: 90vh;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
}

/* Modal Header */
.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
}

.modal-title {
  font-size: 20px;
  font-weight: 600;
  color: #333;
  margin: 0;
}

/* Close Button */
.close-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background 0.3s;
}

.close-button:hover {
  background: #f0f0f0;
}

.close-icon {
  fill: #333;
  width: 20px;
  height: 20px;
}

/* Modal Content Body */
.modal-body {
  overflow-y: auto;
  padding: 10px;
}

/* Modal List Items */
.modal-list-item {
  margin-bottom: 20px;
  padding: 5px 0;
  border-bottom: 1px solid #ccc;
}

.modal-list-item:last-child {
  border-bottom: none;
}

/* Modal List Item Labels */
.modal-list-label {
  font-weight: 600;
  margin-bottom: 5px;
}

/* Modal List Item Text */
.modal-list-text {
  margin: 0;
}

/* Modal Footer */
.modal-footer {
  padding: 10px 0;
  border-top: 1px solid #ccc;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

/* Modal Close Button (Footer) */
.modal-close-button {
  background: #333;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 8px 20px;
  cursor: pointer;
}

.modal-close-button:hover {
  background: #555;
}
/* Default styles for the fixed element */
.log_responsive {
  position: fixed;
  bottom: 20px;
  right: 16px;
}

@media screen and (max-width: 885px) {
  .log_responsive {
      position: fixed;
      bottom: 50px; 
  }
}
@media screen and (max-width: 614px) {
  .log_responsive {
      position: fixed;
      bottom: 60px; 
  }
}

@media screen and (max-width: 380px) {
  .log_responsive {
      position: fixed; 
      display: block; 
      margin: 20px auto; 
  }
}
@media screen and (max-width: 200px) {
  .log_responsive {
      position: fixed; 
      display: block; 
      margin: 50px auto; 
  }
}

.btn_view, .btn_edit, .btn_delete, .btn_imprime{
  padding: 10px 12px;
  border-radius: 5px;
  transition: .5s;
}

.btn_view{
  border: 1px solid #888;
  color: #888;
}

.btn_view:hover{
  background-color: #888;
  color: #fff;
}

.btn_edit{
  border: 1px solid #254c75;
  color: #254c75;
}

.btn_edit:hover{
  background-color: #254c75;
  color: #fff;
}

.btn_delete{
  border: 1px solid red;
  color: red;
}
.btn_record {
  border: 1px solid green;
  color: green;
  background-color: transparent; 
  transition: background-color 0.3s, color 0.3s; 
  padding: 8px 12px; 
  border-radius: 4px; 
}

.btn_record:hover {
  background-color: lightgreen; 
  color: white; 
}

.btn_delete:hover{
  background-color: red;
  color: #fff;
}

.btn_imprime{
  border: 1px solid green;
  color: green;
}

.btn_imprime:hover{
  background-color: green;
  color: #fff;
}

.selected_fixed {
  display: flex;
  justify-content: end;
  margin-top: -3%;
}
@media (max-width: 768px) {
  .settings-form-container {
      flex-direction: column; 
  }

  .selected_fixed {
      flex-direction: column; 
      margin-top: 5%; 
  }

  .custom-button.primary {
      width: 100%; 
      margin-top: 10px; 
  }
}

@media (max-width: 802px) {
  .settings-form-container {
      flex-direction: column; 
  }

  .selected_fixed {
      flex-direction: column; 
      margin-top: 5%;
  }

  .custom-button.primary {
      width: 100%; 
      margin-top: 10px; 
  }
}
