/* MultiDesignButton.css */
.multi-design-button {
  padding: 10px 60px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s ease-in-out;
  grid-column: span 1; /* Set the button to occupy only one grid column */
}

.print-btn {
  padding: 10px 24px;
  background-color: #2663a9;
  color: #fff;
  height: 60px;
  margin-top: auto;
  margin-left: auto;
  margin-right: 0;
  width: auto;
}

.filter-btn {
  display: flex;
  align-items: center;
  justify-content: center; /* Center horizontally */
  flex: 0 0 auto;
  padding: 10px 24px;
  background-color: #ee8432;
  color: #fff;
  height: 60px;
  margin-top: auto;
  margin-left: auto;
  margin-right: 0;
  width: 180px;
}

.save-btn {
  display: flex;
  align-items: center;
  justify-content: center; /* Center horizontally */
  flex: 0 0 auto;
  padding: 10px 24px;
  background-color: #ee8432;
  color: #fff;
  height: 60px;
  width: auto;
  margin-top: auto;
  margin-left: auto;
  margin-right: 0;
}

.print-save-btn {
  display: flex;
  align-items: center;
  justify-content: center; /* Center horizontally */
  flex: 0 0 auto;
  padding: 10px 24px;
  background-color: #2663a9;
  color: #fff;
  height: 60px;
  width: 320px;
  margin-top: auto;
  margin-left: 10px;
  margin-right: 0;
}

.print-add-btn {
  display: flex;
  align-items: center;
  justify-content: center; /* Center horizontally */
  flex: 0 0 auto;
  padding: 10px 24px;
  background-color: #5F6165;
  color: #fff;
  height: 60px;
  width: 320px;
  margin-top: 20px;
  margin-left: 0;
  margin-right: auto;
}

.add-btn {
  display: flex;
  align-items: center;
  justify-content: center; /* Center horizontally */
  flex: 0 0 auto;
  padding: 10px 24px;
  background-color: #ee8432;
  color: #fff;
  height: 60px;
  width: auto;
  margin-top: auto;
  margin-left: auto;
  margin-right: 0;
}

.btn-icon-left {
  margin-right: 8px;
}

.btn-icon-right {
  margin-left: 8px;
}

.primary {
  background-color: #ee8432;
  color: #fff;
}

.primary:hover {
  background-color: #5691b6;
}

.secondary {
  background-color: #ffffff;
  color: #333333;
}

.secondary:hover {
  background-color: #e5e5e5;
}

.danger {
  background-color: #dc3545;
  color: #fff;
}

.danger:hover {
  background-color: #c82333;
}

@media screen and (max-width: 1200px) {
  .add-btn {
    margin-top: 20px;
  }

  .filter-btn {
    margin-top: 10px;
  }
}

@media screen and (max-width: 768px) {
  .add-btn {
    margin-top: 20px;
  }

  .filter-btn {
    margin-top: 10px;
  }
}
