.sidebar-btn {
  display: flex;
  align-items: center;
  color: #5F6165;
  transition: all ease-in-out 0.3s!important;
  height: 45px;
  padding: 7px 8px;
  border-radius: 6px;
  font-size: 14px;
}

.sidebar-btn:hover {
  color: #ffffff!important;
  background-color: #ee8432!important;
  transition: all ease-in-out 0.3s!important;
}
