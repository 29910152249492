.stat-wrapper {
  display: block;
  margin-top: 20px;
}

.stats-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  max-width: 100%;
  margin: 0 auto;
  grid-gap: 10px;
}

.kpis-container {
  display: grid;
  grid-template-columns: repeat(1, 1fr); 
  max-width: 100%;
  margin: 0 auto;
  grid-gap: 50px 10px;
}

@media screen and (max-width: 1200px) {
  .stats-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    max-width: 100%;
    margin: 0 auto;
    grid-gap: 10px;
  }
  
  .kpis-container {
    display: grid;
    grid-template-columns: repeat(1, 1fr); 
    max-width: 100%;
    margin: 0 auto;
    grid-gap: 10px;
  }
  
}

@media screen and (max-width: 768px) {
  .stats-container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    max-width: 100%;
    margin: 0 auto;
    grid-gap: 10px;
  }
}

